/**
 * 標準按鈕
 */
import { Button } from 'antd';

import { PropTypes } from '@/utils/propTypes';
import { styled } from '@/utils/styledComponents';

const StyledButton = styled(Button)`
  height: auto;
  border-radius: 5px;
  padding-top: 16px;
  padding-bottom: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  border: none;

  &[disabled] {
    background-color: #e4e4e4;
    color: #ffffff;
  }
`;
const StyledPrimary = styled(StyledButton)`
  :focus,
  :active {
    background-color: #06c755;
  }
`;

const StyledCancel = styled(StyledButton)`
  background: #f5f5f5;
  color: #949494;
  :focus,
  :active {
    color: #949494;
    background: #f5f5f5;
  }
`;

const StandardButton = ({ children, block = true, ...props }) => (
  <StyledButton block={block} {...props}>
    {children}
  </StyledButton>
);
const PrimaryButton = ({ children, block = true, ...props }) => (
  <StyledPrimary block={block} {...props}>
    {children}
  </StyledPrimary>
);
const CancelButton = ({ children, block = true, ...props }) => (
  <StyledCancel block={block} {...props}>
    {children}
  </StyledCancel>
);

StandardButton.propTypes = {
  children: PropTypes.node.isRequired,
  block: PropTypes.bool,
};
PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  block: PropTypes.bool,
};
CancelButton.propTypes = {
  children: PropTypes.node.isRequired,
  block: PropTypes.bool,
};

export default StandardButton;
export { CancelButton, PrimaryButton };
