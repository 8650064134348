/**
 * LM-LIFF-09 線上客服宣導 https://lmliff.cht.com.tw/contact-service/
 */
import { useCallback, useMemo } from 'react';

import { usePostEvent } from '@/apis/hooks';
import imgContact from '@/assets/images/img-contact-service.svg';
import StandardButton from '@/components/StandardButton';
import useLiff from '@/hooks/useLiff';
import ModuleFlowResult from '@/modules/ModuleFlowResult';
import SVG from '@/patterns/SVG';
import getQueryString from '@/utils/getQueryString';
import { styled } from '@/utils/styledComponents';

const title = '將轉由LINE Mobile客服為您服務';
const message = '疑難雜症不用怕，Q寶有問速答';

const StyledImage = styled(SVG)`
  margin-top: 22px;
  margin-bottom: 32px;
`;

const renderImage = () => {
  return <StyledImage src={imgContact} />;
};

const SecondButton = styled(StandardButton)`
  font-weight: 500;
  background: #f5f5f5;
  color: #949494;
  margin-top: 12px;
`;

const ContactServicePage = () => {
  const lbKey = getQueryString('t');
  const { quitLiff } = useLiff();
  const { data } = usePostEvent(lbKey);
  const redirectUrl = useMemo(() => data?.target_url, [data?.target_url]);
  /**
   * onConfirmToGo 確認按鈕
   * 轉導頁面
   */
  const onConfirmToGo = useCallback(() => {
    /** 不讓使用者上一頁 */
    window.location.replace(redirectUrl);
  }, [redirectUrl]);
  /**
   * onConfirmToBack
   * 取消按鈕:離開LIFF
   */
  const onConfirmToBack = useCallback(() => {
    quitLiff();
  }, [quitLiff]);

  const renderButtons = () => (
    <>
      <StandardButton type="primary" onClick={onConfirmToGo}>
        確定前往
      </StandardButton>
      <SecondButton onClick={onConfirmToBack}>返回聊天室</SecondButton>
    </>
  );
  return (
    <ModuleFlowResult
      title={title}
      message={message}
      renderImage={renderImage}
      renderButtons={renderButtons}
    />
  );
};

export default ContactServicePage;
