import { PropTypes } from '@/utils/propTypes';
import { styled } from '@/utils/styledComponents';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 12px;
`;

const TextWrapper = styled.div`
  ${(props) => (props.$fixedTextHeight ? 'min-height: 116px;' : '')}
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #111111;
  margin-bottom: 8px;
  white-space: pre-wrap;
`;

const Message = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #949494;
  white-space: pre-wrap;
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  width: 100%;
`;

const ModuleFlowResult = ({
  title,
  message,
  renderImage,
  renderButtons,
  fixedTextHeight,
}) => {
  return (
    <Main>
      <TextWrapper $fixedTextHeight={fixedTextHeight}>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </TextWrapper>
      {renderImage()}
      <ButtonGroup>{renderButtons()}</ButtonGroup>
    </Main>
  );
};

ModuleFlowResult.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  renderImage: PropTypes.func,
  renderButtons: PropTypes.func,
  fixedTextHeight: PropTypes.bool,
};

ModuleFlowResult.defaultProps = {
  fixedTextHeight: false,
};

export default ModuleFlowResult;
